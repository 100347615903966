export default {
    name: "copiedMixin",
    data: function () {
        return {
            buttonText: "COPY_LINK",
            successClass: false,
        };
    },
    methods: {
        copySuccess() {
            this.buttonText = "COPY_SUCCESS";
            this.successClass = true;
            setTimeout(() => {
                this.buttonText = "COPY_LINK";
                this.successClass = false;
            }, 3000);
        },
        copyFailed() {
            alert("error");
        },
    },
};
