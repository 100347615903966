<template>
    <div class="refferal-bg">
        <loader v-if="loader" />
        <div class="responsive refferal-bg-does">
            <div class="referal">
                <div class="discription">
                    <div class="discription__wrapper">
                        <div class="features__icon">
                            <i class="i-team-line"></i>
                        </div>
                        <h1>{{ lang.get("REFERRAL_TITLE") }}</h1>
                    </div>
                    <p class="referal_text">{{ lang.get("REFERRAL_DESC") }}</p>
                </div>
                <div class="patric__foto"></div>
            </div>
            <!-- реф линк первый -->
            <div class="information__about__refferal" @click="rollUpMoreDetails = !rollUpMoreDetails">
                <div class="information__inner" :class="{ active_row: rollUpMoreDetails }">
                    <div class="information__row">
                        <div class="information__row__inner">
                            <div class="wrapper__inner">
                                <div class="inner__flex">
                                    <p class="information__name">information about the referral</p>
                                    <div @click.stop="rollUpMoreDetails = rollUpMoreDetails" class="row__days__text">
                                        <i class="i-question-line"></i>
                                        <span class="question-line-text">
                                            {{ lang.get("REWARD_AMOUNT_?") }}
                                        </span>
                                    </div>
                                </div>
                                <div class="left__line">
                                    <i class="i-arrow-drop-down-line"></i>
                                </div>
                            </div>
                        </div>
                        <div class="information__status">
                            <p class="first__status first__margin"><span></span>{{ lang.get("RED") }}</p>
                            <p class="second__status first__margin"><span></span>{{ lang.get("GREY") }}</p>
                            <p class="last__status"><span></span>{{ lang.get("ORANGE") }}</p>
                        </div>
                    </div>
                    <!-- block -->
                    <div @click.stop="rollUpMoreDetails = rollUpMoreDetails" class="inform">
                        <div class="inrorm__inner">
                            <div class="information__level">
                                <div class="level__block">
                                    <p class="level__name">Level 1</p>
                                    <div @click.stop="rollUpMoreDetails = rollUpMoreDetails" class="row__days__text">
                                        <i class="i-question-line"></i>
                                        <span class="question-line-text">
                                            {{ lang.get("LEVEL_1_?") }}
                                        </span>
                                    </div>
                                </div>
                                <div class="level__icon">
                                    <p class="icon__name">3%</p>
                                    <div class="icon__image">
                                        <p class="inform__ball"><i class="i-user-3-fill"></i></p>
                                        <p class="inform__ball__last"><i class="i-user-3-fill"></i></p>
                                    </div>
                                </div>
                                <p class="level__text">
                                    {{ lang.get("LEVEL_1_DESC") }}
                                </p>
                            </div>
                            <div class="information__level">
                                <div class="level__block">
                                    <p class="level__name">Level 2</p>
                                    <div @click.stop="rollUpMoreDetails = rollUpMoreDetails" class="row__days__text">
                                        <i class="i-question-line"></i>
                                        <span class="question-line-text">
                                            {{ lang.get("LEVEL_2_?") }}
                                        </span>
                                    </div>
                                </div>
                                <div class="level__icon">
                                    <p class="icon__name">2%</p>
                                    <div class="icon__image">
                                        <p class="inform__ball"><i class="i-user-3-fill"></i></p>
                                        <p class="inform__ball__second"><i class="i-user-3-fill"></i></p>
                                        <p class="inform__ball__last"><i class="i-user-3-fill"></i></p>
                                    </div>
                                </div>
                                <p class="level__text">
                                    {{ lang.get("LEVEL_2_DESC") }}
                                </p>
                            </div>

                            <div class="information__level">
                                <div class="level__block">
                                    <p class="level__name">Level 3</p>
                                    <div @click.stop="rollUpMoreDetails = rollUpMoreDetails" class="row__days__text">
                                        <i class="i-question-line"></i>
                                        <span class="question-line-text">
                                            {{ lang.get("LEVEL_3_?") }}
                                        </span>
                                    </div>
                                </div>
                                <div class="level__icon">
                                    <p class="icon__name">1%</p>
                                    <div class="icon__image">
                                        <p class="inform__ball"><i class="i-user-3-fill"></i></p>
                                        <p class="inform__ball__second"><i class="i-user-3-fill"></i></p>
                                        <p class="inform__ball__second"><i class="i-user-3-fill"></i></p>
                                        <p class="inform__ball__last"><i class="i-user-3-fill"></i></p>
                                    </div>
                                </div>
                                <p class="level__text">
                                    {{ lang.get("LEVEL_3_DESC") }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="currentAddress !== '0x0000000000000000000000000000000000000000'" class="referal__link">
                <div class="ref__link">
                    <div class="infrm inform-link">
                        <p class="ref__link__text">your referral link</p>
                        <div class="row__days__text">
                            <i class="i-question-line"></i>
                            <span class="question-line-text">
                                {{ lang.get("REF_LINK_?") }}
                            </span>
                        </div>
                    </div>
                    <input class="ref__link__input" type="text" :value="value" disabled />
                    <button class="ref__link__btn" v-clipboard="setReferralLink" v-clipboard:success="copySuccess" v-clipboard:error="copyFailed">
                        <i :class="{ 'i-checkbox-circle-line': successClass }"></i>
                        {{ lang.get(`${buttonText}`) }}
                    </button>
                </div>
                <div class="inform__stats">
                    <div class="invited">
                        <div class="invited__block">
                            <p class="ref__link__text">invited</p>
                            <div class="row__days__text">
                                <i class="i-question-line"></i>
                                <span class="question-line-text">
                                    {{ lang.get("INFITED_INFO_?") }}
                                </span>
                            </div>
                        </div>
                        <p class="invited__number">{{ refNumber ? refNumber : 0 }}</p>
                    </div>
                    <div class="earned">
                        <div class="invited__block">
                            <p class="ref__link__text">earned</p>
                            <div class="row__days__text">
                                <i class="i-question-line"></i>
                                <span class="question-line-text">
                                    {{ lang.get("Earned_?") }}
                                </span>
                            </div>
                        </div>
                        <p class="invited__number">{{ getTotalEarnedInUSD > 0 ? getTotalEarnedInUSD : "0.00" }} $</p>
                    </div>
                </div>
            </div>

            <div v-if="refRewards.length === 0" class="how__to__earn empty__card">
                <!-- <div class="cat"></div> -->
                <div class="search-icon"></div>
                <p class="first__cat__text">{{ lang.get("NOTHING_EARNED") }}</p>
                <a
                    href="https://bringfinance.medium.com/earn-even-more-rewards-with-our-new-referral-program-6e3d97114428"
                    target="_blank"
                    class="two__cat__text"
                    >{{ lang.get("HOW_TO_EARN") }}</a
                >
            </div>

            <!-- new  -->
            <div v-else>
                <!-- таблица -->
                <div class="table">
                    <div class="table__text">
                        <div class="text__for__deck">
                            <p class="text__one__mobile">List token<i class="i-question-line"></i></p>
                            <p class="text__one">Name of asset</p>
                            <div @click.stop="rollUpMoreDetails = rollUpMoreDetails" class="row__days__text">
                                <i class="i-question-line"></i>
                                <span class="question-line-text">
                                    {{ lang.get("NAME_OF_ASSET_INFO") }}
                                </span>
                            </div>
                        </div>
                        <div class="table__text__inner">
                            <div class="table__text__inner__sum">
                                <p class="text__two">SUM</p>
                                <div @click.stop="rollUpMoreDetails = rollUpMoreDetails" class="row__days__text">
                                    <i class="i-question-line"></i>
                                    <span class="question-line-text">
                                        {{ lang.get("SUM_1_INFO") }}
                                    </span>
                                </div>
                            </div>
                            <div class="table__text__inner__sum">
                                <p class="text__last">SUM $</p>
                                <div @click.stop="rollUpMoreDetails = rollUpMoreDetails" class="row__days__text">
                                    <i class="i-question-line"></i>
                                    <span class="question-line-text">
                                        {{ lang.get("SUM_2_INFO") }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-for="refReward in refRewards" :key="refReward.block_timestamp" class="table__inner">
                        <div class="name__token">
                            <p class="for__one__ball" :class="refReward.refTokenName"></p>
                            <p class="text__name__token">{{ tokenTicker(refReward.refTokenName) }}</p>
                        </div>
                        <div class="number__token">
                            <div class="number__token_one">
                                <p class="mobile__number__one">SUM <i class="i-question-line"> </i></p>
                                <p class="desctop__number">
                                    {{ refReward.tokenAmount ? refReward.tokenAmount.toFixed(4) : "0.0000" }} <span class="bbr">BRNG</span>
                                </p>
                            </div>
                            <div class="number__token_two">
                                <p class="mobile__number__two">SUM $ <i class="i-question-line"> </i></p>
                                <p class="desctop__number__two">$ {{ toUSD(refReward.tokenAmount) }}</p>
                            </div>
                        </div>
                        <br />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import copiedMixin from "@/mixins/copiedMixin";
    import MultiLang from "@/core/multilang";
    import { mapState } from "vuex";
    import conf from "../../../../Config.json";
    var Config = conf[window.localStorage.getItem("selectedNetwork")] || conf[conf.PRIMARY_BLOCKCHAIN.chainId];
    import Loader from "../../../components/Loader.vue";
    export default {
        mixins: [copiedMixin],
        name: "Referral",
        components: { Loader },
        computed: {
            ...mapState(["currentAddress", "refNumber", "rewardPageData", "currentRate"]),
            setReferralLink() {
                if (localStorage.getItem("address")) {
                    this.value += localStorage.getItem("address");
                    return this.value;
                } else {
                    return "Referral link cannot be accessed. Please login to your wallet.";
                }
            },
            getTotalEarnedInUSD() {
                let total = 0;
                if (this.currentRate && this.refRewards.length > 0) {
                    for (let reward of this.refRewards) {
                        total += Number(this.toUSD(reward.tokenAmount));
                    }
                    return total.toFixed(2);
                }
                return total.toFixed(2);
            },
        },
        data: function () {
            return {
                lang: new MultiLang(this),
                rollUpMoreDetails: false,
                allEvents: [],
                claimedStakes: [],
                refRewards: [],
                value: `${conf.REF_BASE}?ref=`,
                loader: false,
            };
        },

        // created() {
        //   this.loader = true
        // },
        mounted() {
            let _this = this;
            this.lang.init();

            setTimeout(async function tick() {
                _this.claimAndUnstakeEvents = [];
                try {
                    _this.allEvents = [];
                    if (_this.rewardPageData !== undefined) {
                        //geting events from objects
                        for (let [contract, allEvents] of Object.entries(_this.rewardPageData)) {
                            _this.allEvents.push(...Object.values(allEvents));
                            const refEvents = allEvents?.referralPayout?.referralEvents || [];

                            if (refEvents.length) {
                                _this.refRewards = refEvents.map((el) => {
                                    const contractId = Object.entries(Config.CONTRACT_VERSION_CONFIG).find(
                                        (el) => el[1].POOL_ADDRESS.toLowerCase() === contract.toLowerCase()
                                    )[0];

                                    let token = Config.farmTokens.find(
                                        (token) => token.address.toLowerCase() === el.rewardTokenAddress.toLowerCase() // todo returnr contract id comparison?
                                    );

                                    return {
                                        ...el,
                                        refTokenName: token.name,
                                    };
                                });
                            }
                        }
                        //filter an array to exclude active stakes
                        _this.claimedStakes = [];
                        _this.claimedStakes = _this.allEvents.filter(
                            (el) => (el.unstakeTime && el.unstakeTime > 0) || (el.claimEvents && el.claimEvents.length > 0)
                        );

                        _this.loader = false;
                    }

                    if (!_this.currentAddress) {
                        _this.loader = false;
                    }

                    const inactiveStakeInfoFull = _this.claimedStakes.map((el) => {
                        let token = Config.stakeTokens.find((token) => token.address.toLowerCase() === el.stakedTokenAddress.toLowerCase());

                        return {
                            ...el,
                            stakeTokenName: token.name,
                            farmTokensList: token.farmTokensList,
                            farmTokensRewards: el.claimEvents,
                        };
                    });

                    const unstakedEvents = inactiveStakeInfoFull.filter((event) => event.isActive === false).sort((a, b) => b.unstakeTime - a.unstakeTime);
                    const claimedEvents = inactiveStakeInfoFull.filter((event) => event.isActive === true).sort((a, b) => b.claimTime - a.claimTime);
                    _this.claimedStakes = [...claimedEvents, ...unstakedEvents];
                    // if (Number(_this.refNumber) === null) {
                    //     await _this.$root.core.getReferralsFromContracts(_this.currentAddress);
                    // }
                    setTimeout(tick, 3000);
                } catch (ex) {
                    console.log(ex);

                    setTimeout(tick, 300);
                }
            }, 300);
        },
        methods: {
            toUSD(tokenAmount) {
                return (Number(tokenAmount) * this.currentRate["0x3ecb96039340630c8b82e5a7732bc88b2aeade82"]).toFixed(4);
            },
            tokenTicker(tokenName) {
                return Config.tokenAliases[tokenName];
            },

            getDateOfDeposit(date) {
                const d = new Date(Number.parseInt(`${date.toString() + "000"}`));
                const hours = d.getHours();
                const minutes = d.getMinutes();
                const day = d.getDate();
                const month = d.getMonth();
                const year = d.getFullYear();
                return `${hours < 10 ? "0" + hours : hours}:${minutes < 10 ? "0" + minutes : minutes} | ${day < 10 ? "0" + day : day}.${
                    month + 1 < 10 ? "0" + (month + 1) : month + 1
                }.${year} `;
            },
            getTokenName(tokenAddress) {
                if (Config.stakeTokens.indexOf(tokenAddress) != -1) {
                    return Config.stakeTokens.find((token) => token.address.toLowerCase() === tokenAddress.toLowerCase()).name;
                }

                return Config.farmTokens.find((token) => token.address.toLowerCase() === tokenAddress.toLowerCase()).name;
            },
            findTokenReward(stakeToken, rewardToken, stakeId, contractId) {
                const stake = this.claimedStakes.find(
                    (stk) => stk.stakedTokenAddress.toLowerCase() === stakeToken.toLowerCase() && stk.stakeIndex === stakeId && stk.contractId === contractId
                );

                if (stake && stake.rewardPayoutEvents && stake.rewardPayoutEvents.length) {
                    let totalReward = 0;
                    const rewardObjArray = stake.rewardPayoutEvents.filter((rwd) => rwd.rewardTokenAddress.toLowerCase() === rewardToken.toLowerCase());
                    for (let rewardObj of rewardObjArray) {
                        totalReward = totalReward + rewardObj.reward;
                    }
                    return totalReward ? Number(totalReward).toFixed(4) : "0.0000";
                }

                return "0.0000";
            },
        },
    };
</script>
